import { gql } from "graphql-request";
import type {
  SearchJobPostingsOfPublicCompany as Data,
  SearchJobPostingsOfPublicCompanyVariables as Variables
} from "../types/graphql.generated";
import { useData } from "./useData";

export function useSearchJobPostingsOfPublicCompanyQuery(
  input: Partial<Variables["input"]>
) {
  return useData<Data, Variables>(
    ["public-company-jobs", input.query],
    gql`
      query searchJobPostingsOfPublicCompany(
        $input: SearchJobPostingsOfPublicCompanyInput!
      ) {
        searchJobPostingsOfPublicCompany(input: $input) {
          nodes {
            ... on SearchJobPostingInternal {
              __typename
              ...SearchJobPostingFragment
              version
              minSalary
              maxSalary
              salaryCurrency
              salaryRange
              languages
              tasksDescription
              workEnvironmentDescription
            }
          }
        }
      }

      fragment SearchJobPostingFragment on SearchJobPosting {
        id
        title
        companyName
        details {
          jobType
          employmentType
          isFullTime
          isPartTime
          isRemote
          isJunior
          isIntermediate
          isSenior
          offersHomeOffice
          maximumHomeOfficeDaysPerWeek
          hasPersonnelResponsibility
          hasTravelActivity
          travelActivityPercentage
          vacationDays
          canClaimMoreVacationDays
          canParticipateInSuccessOfCompany
        }
        stack
        businessLocations {
          id
          fullCityName
        }
        programmingLanguages {
          id
          name
        }
        softwareTechnologies {
          mostImportant {
            id
            name
          }
          important {
            id
            name
          }
          relevant {
            id
            name
          }
        }
      }
    `,
    { input: { companySlug: input.companySlug!, query: input.query ?? "" } },
    { enabled: !!input.companySlug }
  );
}
