import React from "react";
import NextLink from "next/link";

type Props = {
  href?: string;
  to: string;
  children: React.ReactNode;
} & Omit<React.HTMLProps<HTMLAnchorElement>, "href">;

/**
 * A wrapper for Next.js's Link component that allows us to use the same API.
 *
 *
 * @param props.href The Next.js path, e.g. `/company/[slug]/jobs/[id]`.
 * @param props.to The path to navigate to, e.g. `/company/acme/jobs/1337`.
 * @param props.children The children to render.
 */
const Link = ({ href, to, children, ...anchorProps }: Props) => {
  return (
    <NextLink href={href ? href : to} as={href ? to : undefined} passHref>
      <a {...anchorProps}>{children}</a>
    </NextLink>
  );
};

export default Link;
