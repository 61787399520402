import { gql } from "graphql-request";
import type { IsLoggedIn as Data } from "../types/graphql.generated";
import { useData } from "./useData";

export function useLoggedInQuery() {
  return useData<Data>(
    "isLoggedIn",
    gql`
      query isLoggedIn {
        isLoggedIn
      }
    `
  );
}
