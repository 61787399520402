import { gql } from "graphql-request";
import type {
  SearchJobPostings as Data,
  SearchJobPostingsVariables as Variables
} from "../types/graphql.generated";
import { useData } from "./useData";

export function useSearchJobsQuery(input: Variables["input"]) {
  return useData<Data, Variables>(
    ["jobs", input.query],
    gql`
      query searchJobPostings($input: SearchJobPostingsInput!) {
        searchJobPostings(input: $input) {
          nodes {
            ... on SearchJobPostingInternal {
              __typename
              ...SearchJobPostingFragment
              version
              minSalary
              maxSalary
              salaryCurrency
              salaryRange
              languages
              tasksDescription
              workEnvironmentDescription
            }
            ... on SearchJobPostingExternal {
              __typename
              ...SearchJobPostingFragment
              descriptionAsHtml
              url
            }
          }
        }
      }

      fragment SearchJobPostingFragment on SearchJobPosting {
        id
        title
        companyName
        details {
          jobType
          employmentType
          isFullTime
          isPartTime
          isRemote
          isJunior
          isIntermediate
          isSenior
          offersHomeOffice
          maximumHomeOfficeDaysPerWeek
          hasPersonnelResponsibility
          hasTravelActivity
          travelActivityPercentage
          vacationDays
          canClaimMoreVacationDays
          canParticipateInSuccessOfCompany
        }
        stack
        businessLocations {
          id
          fullCityName
        }
        programmingLanguages {
          id
          name
        }
        softwareTechnologies {
          mostImportant {
            id
            name
          }
          important {
            id
            name
          }
          relevant {
            id
            name
          }
        }
      }
    `,
    { input },
    { enabled: input.query.length > 0 }
  );
}
