import React, { useEffect } from "react";
import { useIntersection } from "@clubofcode/ui";
import { Config } from "~/utils/config";

type Props = {
  to: string;
  children: React.ReactNode;
} & Omit<React.HTMLProps<HTMLAnchorElement>, "href">;

function hasRel(rel: string, link?: HTMLLinkElement) {
  try {
    link = document.createElement("link");
    return link.relList.supports(rel);
  } catch {
    return;
  }
}

const relPrefetch =
  hasRel("preload") && !hasRel("prefetch")
    ? // https://caniuse.com/#feat=link-rel-preload
      // macOS and iOS (Safari does not support prefetch)
      "preload"
    : // https://caniuse.com/#feat=link-rel-prefetch
      // IE 11, Edge 12+, nearly all evergreen
      "prefetch";

function appendLink(
  href: string,
  rel: string,
  as?: string,
  link?: HTMLLinkElement
): Promise<any> {
  return new Promise((res, rej) => {
    link = document.createElement("link");

    // The order of property assignment here is intentional:
    if (as) link!.as = as;
    link!.rel = rel;
    link!.onload = res;
    link!.onerror = rej;

    // `href` should always be last:
    link!.href = href;

    document.head.appendChild(link);
  });
}

const prefetched: Record<string, boolean> = {};

/**
 * Redirects to our web app (package: client), which will be available under a subdomain
 * in production (e.g. `app.clubofcode.io`).
 * Will also prefetch the resource, if the link scrolls into the viewport.
 *
 * @param props.to A relative route. Make sure it start with a `/` (e.g. `/signin`).
 */
const AppLink = ({ to, children, ...anchorProps }: Props) => {
  const href = Config.App.ORIGIN + to;
  const [setIntersectionRef, visible] = useIntersection({
    rootMargin: "200px"
  });

  useEffect(() => {
    if (visible && !prefetched[href]) {
      appendLink(href, relPrefetch);
      prefetched[href] = true;
    }
  }, [visible, href]);

  return (
    <a ref={setIntersectionRef} href={href} {...anchorProps}>
      {children}
    </a>
  );
};

export default AppLink;
