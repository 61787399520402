import { GraphQLClient } from "graphql-request";
import type {
  RequestDocument,
  Variables as GqlVariables
} from "graphql-request/dist/types";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { Config } from "./config";

const ENDPOINT = `${Config.Api.ORIGIN}/graphql`;

export function useData<Data, Variables = GqlVariables>(
  queryKey: QueryKey,
  document: RequestDocument,
  variables?: Variables,
  config?: UseQueryOptions<Data, Variables>
) {
  const client = new GraphQLClient(ENDPOINT, {
    credentials: "include"
  });

  return useQuery<Data, Variables>(
    queryKey,
    () => client.request(document, variables),
    config
  );
}
