import { gql } from "graphql-request";
import type {
  GetPublicJobPostingById as Data,
  GetPublicJobPostingByIdVariables as Variables
} from "../types/graphql.generated";
import { useData } from "./useData";

// TODO: create utility fragment and import here and in similar hooks
export function useGetPublicJobPostingByIdQuery(input: Variables["input"]) {
  return useData<Data, Variables>(
    ["public-job-posting", input.companySlug, input.jobPostingId],
    gql`
      query getPublicJobPostingById($input: GetPublicJobPostingByIdInput!) {
        getPublicJobPostingById(input: $input) {
          ... on SearchJobPostingInternal {
            __typename
            ...SearchJobPostingFragment
            version
            minSalary
            maxSalary
            salaryCurrency
            salaryRange
            languages
            tasksDescription
            workEnvironmentDescription
            first30DaysDescription
            first90DaysDescription
            programmingLanguages {
              id
              level
            }
            softwareTechnologies {
              mostImportant {
                id
                level
              }
              important {
                id
                level
              }
              relevant {
                id
                level
              }
            }
          }
        }
      }

      fragment SearchJobPostingFragment on SearchJobPosting {
        id
        title
        companyName
        details {
          jobType
          employmentType
          isFullTime
          isPartTime
          isRemote
          isJunior
          isIntermediate
          isSenior
          offersHomeOffice
          maximumHomeOfficeDaysPerWeek
          hasPersonnelResponsibility
          hasTravelActivity
          travelActivityPercentage
          vacationDays
          canClaimMoreVacationDays
          canParticipateInSuccessOfCompany
        }
        stack
        businessLocations {
          id
          fullCityName
        }
        programmingLanguages {
          id
          name
        }
        softwareTechnologies {
          mostImportant {
            id
            name
          }
          important {
            id
            name
          }
          relevant {
            id
            name
          }
        }
      }
    `,
    {
      input: {
        companySlug: input.companySlug!,
        jobPostingId: input.jobPostingId
      }
    },
    { enabled: !!input.companySlug || !!input.jobPostingId }
  );
}
