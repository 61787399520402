import { gql } from "graphql-request";
import type { HasPublicCompanyProfile as Data } from "../types/graphql.generated";
import { useData } from "./useData";

export function useHasPublicCompanyProfileQuery(slug?: string) {
  return useData<Data>(
    ["hasPublicCompanyProfile", slug],
    gql`
      query hasPublicCompanyProfile($slug: String!) {
        hasPublicCompanyProfile(slug: $slug)
      }
    `,
    { slug },
    { enabled: !!slug?.trim().length }
  );
}
