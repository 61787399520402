import { gql } from "graphql-request";
import type { GetPublicCompanyProfileBySlug as Data } from "../types/graphql.generated";
import { useData } from "./useData";

export function usePublicCompanyProfileQuery(slug?: string) {
  return useData<Data>(
    ["publicCompanyProfile", slug],
    gql`
      query getPublicCompanyProfileBySlug($slug: String!) {
        getPublicCompanyProfileBySlug(slug: $slug) {
          id
          name
          description
          sizeLocale
          logoFileUrl
          banner: image(input: { type: BANNER }) {
            ...ImageFragment
          }
          firstAboutUsImage: image(input: { type: ABOUT_US, position: 1 }) {
            ...ImageFragment
          }
          secondAboutUsImage: image(input: { type: ABOUT_US, position: 2 }) {
            ...ImageFragment
          }
          thirdAboutUsImage: image(input: { type: ABOUT_US, position: 3 }) {
            ...ImageFragment
          }
          websiteUrl
          videoUrl
          aboutUsDescription
          sector {
            id
            name
          }
          listableBenefits: benefits {
            ... on CompanyBenefitCommute {
              ...CompanyBenefitFragment
              options
            }
            ... on CompanyBenefitEducationBudget {
              ...CompanyBenefitFragment
              budget
              currency
            }
            ... on CompanyBenefitEquipmentBudget {
              ...CompanyBenefitFragment
              budget
              currency
            }
            ... on CompanyBenefitVacationDays {
              ...CompanyBenefitFragment
              vacationDays
            }
          }
          extraBenefits: benefits {
            ... on CompanyBenefitExtra {
              __typename
              id
              description
            }
          }
        }
      }

      fragment CompanyBenefitFragment on CompanyBenefitInterface {
        __typename
        id
        title
        description
        canBeClaimed
      }

      fragment ImageFragment on Image {
        id
        url
        position
      }
    `,
    { slug },
    { enabled: !!slug?.trim().length }
  );
}
